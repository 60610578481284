import styled from 'styled-components'
import { Spacing } from '../../constants/variables'

const Overlay = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0,0,0,.5);
`

const Content = styled.div`
  position: relative;
  z-index: 10
`

const MainContainer = styled.div`
  padding: ${Spacing.eight} 0 ${Spacing.eight} 0;
`

const InnerContent = styled.div`
  padding: ${Spacing.max} ${Spacing.max};

  @media (max-width: 1024px) {
    padding: ${Spacing.two} ${Spacing.two};
  }
`

const ImageWrapper = styled.div`
    @media (min-width: 768px) and (max-width: 1024px) {
        display: flex;
        align-items: center;
    }
    @media (min-width: 1025px) and (max-width: 1500px) {
        display: flex;
        align-items: center;
    }
    @media (max-width: 767px) {
      * {
        text-align: center;
      }
    }
`

export {
  Overlay,
  Content,
  InnerContent,
  MainContainer,
  ImageWrapper
}