import React, { Component } from 'react';
import styled from 'styled-components'
import Layout from 'components/layout'
import Helmet from 'react-helmet'
import { Row, Col } from 'react-bootstrap'
import { graphql, Link } from 'gatsby'
import AnimationContainer from 'components/animation-container'
import { InnerContent, MainContainer, ImageWrapper } from 'components/container'
import ColumnContainer from 'components/column'
import { Separator } from 'components/separator'
import Metadata from '../constants/metadata'
import Section from 'components/section'
import { Theme } from '../constants/variables'
import PuffLoader from "react-spinners/PuffLoader";

class Loading extends React.Component {
  render() {
    return (
      <div>
        <Layout
          isHome={false}
          useReveal={false}
          sections={Metadata.Navigation.Links}>
          <Section id="about-loading" style={{ backgroundColor: Theme.color.dark }}>
            <MainContainer>
              <Row>
                <Col md={12} className="animate__animated animate__fadeIn">
                  <div style={{
                    width: '100%',
                    minHeight: '500px',
                    margin: '0 auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <div style={{ display: 'inline-block' }}>
                      <PuffLoader
                        size={50}
                        color={"#FBFCFC"}
                        loading={this.props.isLoading}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </MainContainer>
          </Section>
        </Layout>
      </div >)
  }
}

export default Loading;