import styled from 'styled-components'
import { Color, Spacing } from '../../constants/variables'

/**
 * Styled divider.
 */
const Separator = styled.div`
  height: ${Spacing.half};
  width: ${Spacing.four};
  background-color: ${Color.secondary};
  margin-bottom: ${props => props.marginBottom ? `${props.marginBottom * 10}px` : `${Spacing.one}`};
`

/**
 * Styled divider with responsive media queries.
 */
const ResponsiveSeparator = styled.div`
  height: ${Spacing.half};
  width: ${Spacing.four};
  background-color: ${Color.secondary};
  margin-bottom: ${Spacing.three};
`

export {
  ResponsiveSeparator,
  Separator
}